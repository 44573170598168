<template>
  <q-page>
    <div class="row full-width">
      <q-table
          :columns="columns"
          :rows="store.state.categories.data"
          :rows-per-page-options="[lengthData]"
          class="full-width"
          hide-bottom
          row-key="index"
          title="Категории"
          @request="getCategories">
        <template v-slot:top-right>
          <q-btn :to="$route.fullPath + '/addedit'" color="accent" icon="add" label="Добавить" size="sm"/>

          <!--          <q-btn :disabled="store.state.categories.positions.length === 0" color="accent" icon="done" label="Сохранить расположение"-->
          <!--                 size="sm"-->
          <!--                 @click="savePosition"/>-->
        </template>
        <template v-slot:body="props">

          <q-tr :id="props.row.position" :props="props">
            <!--          draggable="true" @dragend="droped($event)"-->
            <!--          @dragover="dragover($event, props.row)" @dragstart="dragit($event, props.row)"-->
            <q-td class="text-center">{{ props.row.name }}</q-td>

            <!--            <q-td class="text-left">-->
            <!--              <q-img :src="props.row.icon_url" fit="contain" loading="lazy" style="width: 80px; height: 80px;">-->
            <!--              </q-img>-->
            <!--            </q-td>-->

            <q-td class="text-center">{{ props.row.description }}</q-td>
            <!--            <q-td class="text-center">-->
            <!--              <long-text-field :maxlen="30" :value="props.row.comment"/>-->
            <!--            </q-td>-->
            <!--            <q-td class="text-center">{{ props.row.rating }}</q-td>-->
            <!--            <q-td class="text-center">{{ $filters.toHumanDate(props.row.date) }}</q-td>-->
            <!--            <q-td class="text-center">{{ props.row.position }}</q-td>-->
            <q-td class="text-center">
              <q-btn
                  :to="$route.fullPath +'/addedit/'+props.row.name"
                  dense
                  icon="edit"
                  round
                  size="sm"
                  @click="addEditRedirect(props.row)">
                <q-tooltip>Редактировать</q-tooltip>
              </q-btn>
              <q-btn
                  dense
                  icon="delete"
                  round
                  size="sm"
                  @click="deleteCategory(props.row.name)">
                <q-tooltip>Удалить</q-tooltip>
              </q-btn>

            </q-td>
          </q-tr>

        </template>

      </q-table>
    </div>
  </q-page>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue';
import { CategoryApi } from '@/api/apis/category-admin-api';
import { useStore } from 'vuex';
import LongTextField from '@/components/text/longTextField';
import $filters from '../../helpers/filters';

const columns = [
  {
    name: 'name',
    field: 'name',
    align: 'center',
    label: 'Название категории',
  },
  {
    name: 'description',
    field: 'description',
    align: 'center',
    label: 'Описание категории',
  },
  {
    name: 'Action',
    label: '',
    field: 'Action',
    align: 'right',
  },
];

export default defineComponent({
  computed: {
    $filters() {
      return $filters;
    },
  },
  components: { LongTextField },
  setup() {
    const store = useStore();
    // const moveItem = ref(null);
    const loading = ref(false);
    const lengthData = ref(0);

    async function getCategories() {
      loading.value = true;
      const response = await new CategoryApi().categoryGetItems();
      store.dispatch('updateCategories', response.data);
      lengthData.value = response.data.length;
      loading.value = false;
    }

    onMounted(() => getCategories());

    // const dragit = (event, item) => {
    //   moveItem.value = item
    // };
    //
    // const dragover = (e, item) => {
    //   let cloneCategories = [...store.state.categories.data]
    //   let ItemIndex = cloneCategories.indexOf(moveItem.value)
    //   let woveItemIndex = cloneCategories.indexOf(item);
    //   cloneCategories.splice(ItemIndex, 1)
    //   cloneCategories.splice(woveItemIndex, 0, moveItem.value)
    //   store.dispatch('updateCategories', cloneCategories)
    // };
    // const droped = () => {
    //   store.dispatch('updateCategoriesDropped', store.state.categories.data)
    // };

    const addEditRedirect = (item) => {
      store.dispatch('editCategoryItemUpdate', item);
    };

    async function deleteCategory(name) {
      const response = await new CategoryApi().deleteCategory(name);
      if (response.status === 202) {
        store.dispatch('deleteCategoryItem', name);
      }
    }

    // async function savePosition() {
    //   const response = await new CategoryApi().updatePositionCategory(store.state.categories.positions)
    //   if (response.status === 202) {
    //     store.dispatch('clearCategoriesPositions')
    //     Notify.create({
    //       type: 'positive',
    //       message: 'Сохранено',
    //     });
    //   }
    // }

    return {
      lengthData,
      addEditRedirect,
      deleteCategory,
      // savePosition,
      // dragover,
      // dragit,
      // droped,
      getCategories,
      columns,
      store,

    };
  },
});
</script>
<style>
.q-btn {
  margin-left: 10px;
}
</style>
